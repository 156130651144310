// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Analytics from "../../blocks/analytics/src/Analytics";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistrationWeb from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmialAccountRegistrationConfirmation from "../../blocks/email-account-registration/src/EmialAccountRegistrationConfirmation.web"
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Filteritems from "../../blocks/filteritems/src/FilterItemsBlock.web";
import ProductDiscription from "../../blocks/filteritems/src/ProductDiscription.web"
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu.web"
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CustomForm from "../../blocks/customform/src/CustomForm.web"
import EditProduct from "../../blocks/filteritems/src/EditProduct.web"
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPassword.web';
import SellerProfileDescription from '../../blocks/productdescription/src/SellerProfileDescription.web'
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import CommunityForum from "../../blocks/communityforum/src/CommunityForum";
import Notifications from "../../blocks/notifications/src/Notifications";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";


const routeMap = {
NavigationMenu:{
  component: NavigationMenu,
  path:"/NavigationMenu"
},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistrationWeb,
path:"/EmailAccountRegistration"},
EmialAccountRegistrationConfirmation:{
component :EmialAccountRegistrationConfirmation,
path:"/EmialAccountRegistrationConfirmation"
},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
ProductDiscription:{
  component:ProductDiscription,
  path:"/ProductDiscription",
  complexParams: true,
  exact:false
},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
SellerProfileDescription:{
  component: SellerProfileDescription,
 path:"/SellerProfileDescription"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordWeb:{
  component:ForgotPasswordWeb,
 path:"/ForgotPasswordWeb"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: EmailAccountRegistrationWeb,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: Subscriptionbilling2,
    path: '/InfoPage',
  },
  CustomForm: {
    component: CustomForm,
    path: '/AddProduct',
    exact: true,
  },
  EditProduct: {
      component: EditProduct,
      path: '/EditProduct',
      complexParams: true
      // exact: true,
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <link href='https://fonts.googleapis.com/css?family=Inter' rel='stylesheet'></link>
      <View style={{ height: '100vh', width: '100vw' }}>
        <ToastContainer/>
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;