import React from "react";

import { Input } from '@builder/component-library';

// Customizable Area Start

import { StyleSheet } from "react-native";

import {Typography,Button,Box,TextField,FormLabel,Divider,InputAdornment,IconButton,CircularProgress} from "@mui/material";
import Paper from '@mui/material/Paper';
import SideImgLayout from "../../../components/src/Layout.web";
import * as Yup from 'yup';
import { Formik, Form, Field, } from "formik";
import { imgSignupLogo, imgVisa } from "./assets";
// Customizable Area End

import Subscriptionbilling2Controller, {
  Props,
  configJSON,
} from "./Subscriptionbilling2Controller";
import { borderRadius } from "react-select/src/theme";

export default class Subscriptionbilling2 extends Subscriptionbilling2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  

// Form component with Formik
 PaymentForm = () => {
  const initialValues = {
    cardNumber: '',
    cardholderName: '',
    expiration: '',
    cvv: '',
  };

  const handleSubmit = async (values:{
    cardNumber:string;
    expiration:string;
    cvv:string;
  }) => {
    this.setState({ payNowLoading: true });
     this.createStripeToken({
      cardNumber: values.cardNumber,
      expiration: values.expiration,
      cvv: values.cvv,
    });
  };
  const PaymentSchema = Yup.object().shape({
    cardNumber: Yup.string()
    .matches(/^\d{16}$/, 'Card number must be exactly 16 digits')
    .required('Card number is required'),
  cardholderName: Yup.string()
    .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9\s!@#$%^&*()\-+=]*$/, 'Cardholder name can not consist only of spaces, digits or special characters')
    .required('Cardholder name is required'),
  expiration: Yup.string()
    .matches(
      /^(0[1-9]|1[0-2])\/\d{4}$/,
      'Expiration must be in MM/YYYY format with a valid month'
    )
    .required('Expiry date is required'),
  cvv: Yup.string()
    .matches(/^\d{3,4}$/, 'CVV must be exactly 3-4 digits')
    .required('CVV is required'),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PaymentSchema}
      onSubmit={handleSubmit}
      data-testid='formid'
    >
      {({ errors, touched, handleChange }) => (
        <Form autoComplete="off" style={{ width: '100%' }}>
          <Box>
            <Typography sx={{ fontSize: '24px', fontWeight: '700' , fontFamily:'inter',color:"black", paddingBottom:"24px" }}>
              Payment details
            </Typography>
            <Divider/>

            <Box mt={"24px"}>
              <FormLabel sx={{ color:"#334155",fontSize: '14px', fontWeight: '700' ,fontFamily:'Inter', marginBottom:"4px" }}>Card number</FormLabel>
              <Field
                as={TextField}
                size={"small"}
                name="cardNumber"
                fullWidth
                placeholder={"0000 0000 0000 0000"}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <img src={imgVisa} alt="icon" style={{ width: 24, height: 24 }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#CBD5E1',
                      borderRadius:"8px"
                    },
                    '&:hover fieldset': {
                      borderColor: '#CBD5E1',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#CBD5E1',
                    },
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: '#94A3B8',
                    fontWeight: '400',
                    fontSize:"16px",
                    fontFamily:"Inter"
                  },
                }}
                error={touched.cardNumber && Boolean(errors.cardNumber)}
                helperText={touched.cardNumber && errors.cardNumber}
              />
            </Box>

            <Box mt={"16px"}>
            <FormLabel sx={{ color:"#334155",fontSize: '14px', fontWeight: '700' ,fontFamily:'Inter',marginBottom:"4px" }}>Cardholder name</FormLabel>
              <Field
                as={TextField}
                size={"small"}
                name="cardholderName"
                placeholder="Cardholder name"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#CBD5E1',
                      borderRadius:"8px"
                    },
                    '&:hover fieldset': {
                      borderColor: '#CBD5E1',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#CBD5E1',
                    },
                  },
                  '& .MuiInputBase-input::placeholder': {
                    color: '#94A3B8',
                    fontWeight: '400',
                    fontSize:"16px",
                    fontFamily:"Inter"
                  },
                }}
                fullWidth
                onChange={handleChange}
                error={touched.cardholderName && Boolean(errors.cardholderName)}
                helperText={touched.cardholderName && errors.cardholderName}
              />
            </Box>

            <Box mt={"16px"} paddingBottom={"24px"} display="flex" justifyContent="space-between">
              <Box width="48%">
              <FormLabel sx={{ color:"#334155",fontSize: '14px', fontWeight: '700' ,fontFamily:'Inter',marginBottom:"4px" }}>Expiry Date</FormLabel>
                <Field
                  as={TextField}
                  size={"small"}
                  name="expiration"
                  placeholder="MM / YYYY"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#CBD5E1',
                        borderRadius:"8px"
                      },
                      '&:hover fieldset': {
                        borderColor: '#CBD5E1',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#CBD5E1',
                      },
                    },
                    '& .MuiInputBase-input::placeholder': {
                      color: '#94A3B8',
                      fontWeight: '400',
                      fontSize:"16px",
                      fontFamily:"Inter"
                    },
                  }}
                  fullWidth
                  onChange={handleChange}
                  error={touched.expiration && Boolean(errors.expiration)}
                  helperText={touched.expiration && errors.expiration}
                />
              </Box>
              <Box width="48%">
              <FormLabel sx={{ color:"#334155",fontSize: '14px', fontWeight: '700' ,fontFamily:'Inter',marginBottom:"4px" }}>CVV</FormLabel>
                <Field
                  as={TextField}
                  size={"small"}
                  name="cvv"
                  placeholder="3-4 digits"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#CBD5E1',
                        borderRadius:"8px"
                      },
                      '&:hover fieldset': {
                        borderColor: '#CBD5E1',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#CBD5E1',
                      },
                    },
                    '& .MuiInputBase-input::placeholder': {
                      color: '#94A3B8',
                      fontWeight: '400',
                      fontSize:"16px",
                      fontFamily:"Inter"
                    },
                  }}
                  fullWidth
                  onChange={handleChange}
                  error={touched.cvv && Boolean(errors.cvv)}
                  helperText={touched.cvv && errors.cvv}
                />
              </Box>
            </Box>
            <Divider />
            <Box mt={"24px"}>
          <Box mb={"16px"} sx={{display:'flex' ,justifyContent:'space-between', alignItems:"center"}}>
            <Typography sx={{ fontSize: '18px', fontWeight: '400' ,fontFamily:'Inter', color:"#64748B" }}>
                Subscription 
              </Typography>
              <Typography sx={{ fontSize: '20px' ,fontFamily:'Inter', color:"#0F172A",fontWeight:"700" }}>
                ${this.state.lastElementWithGreatestId.attributes.price}
              </Typography>
              </Box>
              <Typography sx={{ fontSize: '18px', color: '#64748B'  ,fontFamily:'Inter', fontWeight:"400"}}>
                <i style={{color:'red'}}>*</i> bill will be sent to your registered Email ID within 24 hrs
              </Typography>

              <Typography style={{ fontSize: '14px', color: 'red' ,fontFamily:'Inter'}}>
                      {this.state.ApiError}   </Typography>
            </Box>

            <Box mt={"16px"} display="flex" justifyContent="space-between">
              <Button
                type="submit"
                fullWidth
                data-test-id='payNowBtn'
                sx={{
                  fontSize: '16px',
                  padding: '16px',
                  backgroundColor: '#067B3E',
                  color: "white",
                  fontFamily: "Inter",
                  fontWeight: 700,
                  lineHeight: "24px",
                  textTransform: "none",
                  height: "56px",
                  borderRadius: "8px",
                  marginBottom:"16px",
                  '&:hover': {
                    backgroundColor: '#067B3E', 
                    color: 'white',          
                  },
                }}
              >
                {this.state.payNowLoading ? <CircularProgress size="27px" color="inherit" /> : "Pay now"}                
              </Button>
            </Box>
            <Box>
              <Button
                fullWidth
                variant="contained"
                data-test-id="CancelButtonSecond"
                onClick={() => this.setState({isFormVisible:!this.state.isFormVisible})}
                sx={{
                  fontSize: '16px',
                  padding: '16px',
                  backgroundColor: '#F1F5F9',
                  color:'#64748B',
                  fontFamily: "Inter",
                  lineHeight: "24px",
                  fontWeight: 700,
                  height: "56px",
                  borderRadius: "8px",
                  textTransform: "none",
                  cursor: "pointer",
                  '&:hover': {
                    backgroundColor: '#F1F5F9', 
                    color: '#64748B',          
                  },
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <SideImgLayout data-test-id='layout' backgroundImage="https://cannabismuseum-amsterdam.com/wp-content/uploads/2023/08/Cannabis-Clones.jpg">
        {!this.state.isFormVisible &&
        <Box sx={{ 
          alignItems: 'center',
          height: '100%',
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          position: 'relative', }}>
        <img src={imgSignupLogo} width={"106px"} height={"106px"} style={{
        top: '0',
        position: 'absolute',
        left: '0',
      }}/>
        <Paper
        
          sx={{
            padding: "0px 32px",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          elevation={0}
        >
          <div style={{width:'212px', height:'212px'}}>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 212">
  <path fill="#E6A02C" d="M131 38h3c5 0 9 1 14 4l1 8v3l-2 6h12c5 0 7 0 11 2 3 3 3 6 3 10l-3 8v2l-8 14h3c4 1 7 2 10 6 1 7-1 12-3 18l-1 2c-4 11-4 11-9 13l17 6 3 1 1 3-2 4c-3 4-3 6-2 11v2a278 278 0 0 1 1 8c-32-8-32-8-39-15v21l-3 4-9 1h-4a3143 3143 0 0 1-51 1c-4-1-5-2-7-5l-1-5v-17l-3 2c-8 6-25 13-36 13l2-12c1-5 0-5-2-9l-2-2-1-2 1-3 4-2h3l3-1 5-2h2l3-2-3-2c-4-3-5-8-7-13l-1-3c-1-5-3-9-2-14 3-5 9-7 14-9l-1-1c-5-8-9-16-9-25l3-7c4-2 8-2 13-2h9l-1-2v-9c0-4 1-5 4-7 20-7 46-4 67-2ZM45 68c1 9 5 17 11 23h6l3-1h3l-1-2-3-19-3-1H45Zm100 0-1 9-3 13c6 2 6 2 13 1 5-6 10-15 10-23h-19Zm-80 51 1 3 5 21h6l2-8 10-2c2-6 2-11 2-17-9 0-17 0-26 3Zm53-3c-2 6 0 11 2 17h2l2 1h2l3 2 1 7h6l1-2 2-9 1-3 1-3v-3l2-4c-8-2-16-3-25-3Z"/>
  <path fill="#EDC667" d="M156 58h3c4 0 7 1 11 3 3 3 3 6 3 10l-3 8v2l-8 14h3c4 1 7 2 10 6 1 7-1 12-3 18l-1 2c-4 11-4 11-9 13l17 6 3 1 1 3-2 4c-3 4-3 6-2 11v2a278 278 0 0 1 1 8c-32-8-32-8-39-15v21l-3 4-9 1h-4a3143 3143 0 0 1-51 1c-4-1-5-2-7-5v-15c0-9 0-9 3-13l1-4 6-1v-3l1-5 5 1v7h53l1-7 1-4v-2l1-2 1-2 2-6-21-4 2-2 4-5c1-3 3-4 5-6 6-2 12-2 18-2h-15l5-11h2c7 2 7 2 13-1l2-4 2-3 6-17h-22v-6l13-1Z"/>
  <path fill="#614A40" d="m78 135 5 1v7h53l2 7 2 2v12l1 4v6l-3 5-9 1h-4a3143 3143 0 0 1-51 1c-4-1-5-2-7-5v-15c0-9 0-9 3-13l1-4 6-1v-3l1-5Z"/>
  <path fill="#E66583" d="m45 90 4 3 7 4 2 1 8 1-3-4-2-1h-2l-2-1v-2l9-1h4l1 3 1 3 3 1v6h2c3 1 4 3 6 6l2 2 1 3 2 1c-2 2-5 2-8 2l-15 2 1 3c3 6 5 14 5 21l2 1h-2v3c-2 5-4 7-9 10-10 5-24 12-35 12l2-12c1-5 0-5-2-9l-2-2-1-2 1-3 4-2h3l3-1 5-2h2l3-2-3-2c-4-3-5-8-7-13l-1-3c-1-5-3-9-2-14 3-5 9-7 14-9l-1-3Z"/>
  <path fill="#EBC052" d="M131 38h3c5 0 9 1 14 4l1 8v3l-2 4v-4h-2a437 437 0 0 1-71-4h-6l-3 1h-4v6h-1v-9c0-3 0-3 2-5 14-11 51-5 69-4Z"/>
  <path fill="#DD4A54" d="m140 125 6 4c7 5 14 7 22 9l9 3 3 1 3 1-5 9v4l1 3v2a1057 1057 0 0 1 1 8c-35-9-35-9-43-19-4-7 0-15 2-22l1-3Z"/>
  <path fill="#D32F33" d="m45 90 4 3 7 4 2 1 8 1-3-4-2-1h-2l-2-1v-2l9-1h4l1 3 1 3 3 1v6h2c3 1 4 3 6 6l2 2 1 3 2 1c-2 2-5 2-8 2l-4 1h-6c-7 1-12 0-18-2l-2-1c-5-2-10-4-14-8-1-6-1-6 2-9l8-5-1-3Z"/>
  <path fill="#2E1208" d="m72 145 4 1 1 9-1 3v6l-1 7h2a4990 4990 0 0 1 49 0h7l5 1 1 5-1 2-9 1h-10a3575 3575 0 0 1-42 0h-3c-4 0-5-1-7-4l-1-12v-3c0-7 1-11 6-16Z"/>
  <path fill="#D8B79E" d="m104 60 2 1v2h2l3 10h14v4l-4 3c-2 2-4 4-4 7l1 14-8-4c-4-2-5-2-9-1a97 97 0 0 0-8 4l-2 1v-3a1954 1954 0 0 0 1-13l-6-5v-2h-2v-3l14-2 3-10h2l1-3Z"/>
  <path fill="#ECBE62" d="M108 120c4 1 4 1 7 4l1 2 2-4 2 11h3l5 1c2 2 2 2 2 9H83v-7l-4-1c2-2 2-2 6-2l11 1 1-3h2v-2l2-6 7-3Z"/>
  <path fill="#EEC65F" d="M91 149h5a1659 1659 0 0 1 17 0h3l6 1v17H85c0-18 0-18 6-18Z"/>
  <path fill="#D93D4D" d="m160 94 1 2 4 2c3 0 5 1 8 3v5c-4 4-9 6-14 8l-6 2c-6 3-10 3-17 2l-6-1-9-1 2-2 5-7c2-3 3-4 6-4l9-1c6-1 10-2 15-5l2-3Z"/>
  <path fill="#EDC162" d="M47 59h16l-2 6H44c4 13 4 13 11 24l2 4 6 2h3l2 1-2 1 1 3c-6 1-11-1-16-4l-4-5h-3c-9-19-9-19-7-27 2-4 5-5 10-5Z"/>
  <path fill="#D93B2D" d="m33 107 2 1c6 4 12 7 19 9l10 5c0 3-1 5-3 7-5 3-10 5-16 5-6-4-8-8-9-15l-1-2-2-10Z"/>
  <path fill="#DD4750" d="M173 108c1 4 0 7-2 11v3l-7 11h-10c-8-4-8-4-11-8l1-4c5-4 11-5 16-7s9-3 13-6Z"/>
  <path fill="#8A786C" d="m78 135 5 1v7h53l-5 4-1 1h-7v17h-1l-1-15H86l-1 17h-1l-1-18-4-1-7-3-1 2v-3l6-1v-3l1-5Z"/>
  <path fill="#DD7C23" d="m91 110 4 2v2l2-1h20l2-2h3l1 3-2 2h-3l-1 11-2-2c-3-3-3-3-7-4l-6 3c-2 2-2 4-3 7l-3 3h-7l2-18-4-1-1-2 5-3Z"/>
  <path fill="#F0C573" d="m163 88 2 2-3 5h3c4 1 7 3 10 6v8l-10 4a1393 1393 0 0 0-13 5l-3 1-2 1-3 3v2c8 7 19 10 29 13l4 1 3 1 3 3c-7-1-12-2-18-5l-9-3c-6-2-11-6-16-11 2-4 3-5 6-7a206 206 0 0 1 11-3l9-4 3-1c3-3 3-5 4-8l-5-2-2-1-6-2-5 4-13 1h-7l5-11 15 1 1 4h3l1-2 3-5Z"/>
  <path fill="#F0D37C" d="M131 38h3c5 0 9 1 14 4l1 8v5h-1l-1-10h-3v-2l-4 1-11 1h-2c-10 2-21 1-30-3l-2-2-8-1h-8v-1h52Z"/>
  <path fill="#E1D1C7" d="m104 60 2 1v2h2l3 10h14l1 3-9 4-3 1c-8 3-14 2-22-1l-4-2-4-3 14-2 3-10h2l1-3Z"/>
  <path fill="#41271E" d="m72 145 4 1 1 9-1 3v6l-1 7h2a15064 15064 0 0 1 53 0h3l5 1v2H70l-2 4c-2-4-1-9-1-14v-3c-1-7 0-11 5-16Z"/>
  <path fill="#675045" d="m76 146 8 2v19h38l1-2v4l3 1H75l1-24Z"/>
  <path fill="#EBC463" d="M80 135h2l12 1h3a2270 2270 0 0 0 19 1h8l1-1h5v7H83v-7l-3-1Z"/>
  <path fill="#EFCC6E" d="M74 39h19c3 0 4 1 6 3 10 5 21 4 32 2l8-1h5v2h3l1 3h-2v-2l-3 1c-18 3-35 4-53-2l-7-2v-3c-12 1-12 1-21 6l-2 2 1-6c4-3 8-3 13-3Z"/>
  <path fill="#E08B19" d="M76 52h3l37 1h12l3 1 16 1v3h-3c-14-2-28-2-41-2h-3c-13 0-25 0-37 2l-1-5c5-2 9-1 14-1Z"/>
  <path fill="#DB5712" d="m45 90 4 3 7 4 2 1 8 1-3-4-2-1h-2l-2-1v-2l9-1h4l1 3 1 3 3 1v6h2c3 1 4 3 6 6l3 3-5-1-1-4h-2l-1-2-7-1c-10-1-17-3-24-10l-1-4Z"/>
  <path fill="#EFC86C" d="M156 58h3c5 0 7 1 11 3 3 3 3 6 3 10l-1-2-2 1-5 3v-7l-22-1v-6l13-1Z"/>
  <path fill="#E9B049" d="M111 117h7l-1 10-2-2c-3-3-3-3-7-4l-6 3c-2 2-2 4-3 7l-3 3h-7l2-15h1l1 7 6-7c4-2 8-2 12-2Z"/>
  <path fill="#EADFD3" d="M104 64c4 1 5 3 7 7v2h14l1 3-9 4-3 1h-3l-2 1-4 1-1-12v-7Z"/>
  <path fill="#EA8099" d="M161 94c11 3 11 3 14 7v8l-10 4a1393 1393 0 0 0-13 5l-3 1-2 1-3 3v2c8 7 19 10 29 13l4 1 3 1 3 3c-7-1-12-2-18-5l-9-3c-6-2-11-6-16-11 2-4 3-5 6-7a206 206 0 0 1 11-3l9-4 3-1c3-3 3-5 4-8l-2-1-10-4v-2Z"/>
  <path fill="#523328" d="m72 145 4 1c2 8 0 17-1 25h61v1H72l-1-21h-3l4-6Z"/>
  <path fill="#EDCB7C" d="M53 59h10l-2 6h-8l-3 1h-4l-6 1-2 4-2-1c0-4 0-6 3-9 4-3 9-2 14-2Z"/>
  <path fill="#D3333E" d="m47 95-1 2v10l3 3v2l4 1v2c-6-2-12-3-17-8-1-6-1-6 2-9 6-5 6-5 9-3Z"/>
  <path fill="#EDC349" d="M146 46v2h2v9h-1v-6h-3a2818 2818 0 0 1-54-2l1-2h3l17 1h7l28-2Z"/>
  <path fill="#EAC981" d="M86 149h35v5l-1 2-1-2-32-1-1-4Z"/>
  <path fill="#E7A824" d="m77 49 7 1 18 1 45 2v2h-17l-2-1a795 795 0 0 1-51-1h-5l-6-2-1-2c5-2 7-2 12 0Z"/>
  <path fill="#D09A64" d="M104 84c4 3 7 6 9 10l2 3 3 4-8-4-7-2-8 4h-2l6-9 2-3 3-3Z"/>
  <path fill="#F3DFAE" d="m95 37 36 1v1l-5 1v2h-23l-5-1v-3l-3-1Z"/>
  <path fill="#EDCD79" d="m119 131 1 2h3c3 0 3 0 5 2l1 1c-5 2-9 2-14 2h-2a929 929 0 0 1-19-1h-3c-4 0-8 0-12-2l4-2h2a602121152 602121152 0 0 0 12 1h4a380053551 380053551 0 0 1 18 1v-4Z"/>
  <path fill="#EAB834" d="M90 47v2a438 438 0 0 0 57 2v2c-21 0-43 0-64-3v-2l7-1Z"/>
  <path fill="#764E2F" d="M122 165v2l1 2 3 1H84l1-3h37v-2Z"/>
  <path fill="#E76F8C" d="m36 98 1 5 1 5c7 4 13 6 20 8l7 3 2 6h-2l-1 2-5 5-3 1-2 1-2-1 2-1 3-2 3-1 3-5v-2l-16-7c-5-1-9-3-13-6l-2-1v-6l4-4Z"/>
  <path fill="#E7B341" d="M68 42v2h7l7 2 1 4c-4 1-5 1-8-1-5-1-6-1-10 1h-4v6h-1c-1-8-1-8 2-11s3-3 6-3Z"/>
  <path fill="#EFC95F" d="M144 43v2h3l1 3h-2v-2l-3 1c-18 3-36 4-53-2v-1h5l3 1 6 1c14 1 27-3 40-3Z"/>
  <path fill="#E9B138" d="m85 150 2 1v14h34l1 2H85v-17Z"/>
  <path fill="#F0D793" d="M79 38h19v3l28 1v-2l-4-1h19v2l-12-1-1 3c-9 2-20 2-29 0l-2-1c-3-3-6-3-10-3h-8v-1Z"/>
  <path fill="#957643" d="m78 135 5 1v7h46v1a11959 11959 0 0 1-40 0h-9l-3-1 1-8Z"/>
  <path fill="#E9BE5C" d="M165 66h1v6l1-1c2-2 2-2 5-2 0 7-2 12-5 18h-3l1-3 2-9-4 1v3h-2a2167 2167 0 0 1 2-8l2-5Z"/>
  <path fill="#E8E0DA" d="M99 74c3 3 4 4 4 8-7 0-13-3-19-6 5-3 10-3 15-2Z"/>
  <path fill="#E9AF52" d="M98 55h24l7 2h-20l-2 6-3-2-2 2v-5l-4-1v-2Z"/>
  <path fill="#E1710E" d="m91 110 4 2v2l2-1h20l2-2h3l1 3c-6 3-12 2-18 2h-4c-5 0-10 0-15-2l5-4Z"/>
  <path fill="#E3921B" d="M71 52h10l30 1v1l-49 1v-2l9-1Z"/>
  <path fill="#E0851B" d="m136 96-1 5 18-1c-3 2-4 2-7 2-9 0-9 0-16 4l-3 4-4 3v-2l2-1 5-8c3-6 3-6 6-6Z"/>
  <path fill="#E2D4CC" d="m106 84 9 1a449 449 0 0 1 2 15l-4-5-2-2-2-3-2-2-1-4Z"/>
  <path fill="#E3993F" d="m45 90 4 3 7 4 2 1 8 1v-2l5-1 1 5c-15 1-15 1-20-2l-7-7v-2Z"/>
  <path fill="#D1A073" d="m84 76 9 3 3 1h2l2 1 3 3-10 3-4-5-3-2v-2h-2v-2Z"/>
  <path fill="#ECD087" d="m44 68 4 9c1 4 4 8 7 11l2 5-6-2-5-9-1-3-1-2-1-3v-4l1-2Z"/>
  <path fill="#D74D5D" d="m47 95-1 2-1 5-3-1v-2h-2l1 11-5-3c-1-6-1-6 2-9 6-5 6-5 9-3Z"/>
  <path fill="#F2D6A2" d="m106 125 5 1c2 3 2 4 2 8h-9l2-9Z"/>
  <path fill="#EEBF59" d="m163 88 2 2-3 4h-2l-1 2-4 4-6 1h-5l1-4 8 1v-3h6l1-2 3-5Z"/>
  <path fill="#81736B" d="M136 143c-3 3-5 3-9 3h-11a2040 2040 0 0 1-38 0v-2a83217 83217 0 0 1 39-1h19Z"/>
  <path fill="#D43D25" d="M66 90h2l3 1v5h-5l-2-1-7-2v-2l9-1Z"/>
  <path fill="#D97B23" d="M44 65h21l1 4-2-1H44v-3Z"/>
  <path fill="#F3DA97" d="m119 131 1 2h6l-2 1v2H86v-1l-5-1v-1l10 1h10a380053551 380053551 0 0 1 18 1v-4Z"/>
  <path fill="#77665E" d="m78 143 1 3 40 1v1a1406 1406 0 0 1-20 1c-19 1-19 1-27-4l-1 2v-3l7-1Z"/>
  <path fill="#D0A27C" d="m104 84 2 2h-2v4c0 3-1 4-3 6-5 3-5 3-8 3l6-9 2-3 3-3Z"/>
  <path fill="#F0DCA4" d="M146 58a681 681 0 0 1 24 3l2 4-6-3a83 83 0 0 0-7 0l-3-1h-10v-3Z"/>
  <path fill="#E08628" d="M121 116c-5 2-9 2-14 2l-8 1-2 4-3 4c0-4 1-7 3-11l10-1h8l6 1Z"/>
  <path fill="#E3A02B" d="M77 49c4 2 8 2 12 2v1l-9 1h-8l-6-2-1-2c5-2 7-2 12 0Z"/>
  <path fill="#EFC987" d="M108 122c3 0 3 0 5 2v5l-1-1c-2-2-2-2-6-2l-2 8-3-1 1-4v-3c1-3 2-4 6-4Z"/>
  <path fill="#E18112" d="M71 54h3a501338304 501338304 0 0 0 14 0v1l-25 3-1-3 9-1Z"/>
  <path fill="#DF5F70" d="m140 90 11 1-1 3-12 2 2-6Z"/>
  <path fill="#EFD06B" d="m113 127 3 1v6l3 1H91v-1h22v-7Z"/>
  <path fill="#E4A038" d="M111 117h7l-1 6-2-1c-4-3-8-3-12-2l-4 3-2-1c4-5 8-5 14-5Z"/>
  <path fill="#DBA34C" d="M37 69c3 3 4 6 5 10l4 10h-3c-3-7-6-12-6-20Z"/>
  <path fill="#D5903B" d="m78 135 5 1v7h-6l1-8Z"/>
  <path fill="#E7BD4B" d="M128 136h2v7h-6v-6l4-1Z"/>
  <path fill="#E9B65F" d="m56 94 12 2-2 1 1 3c-5 0-7 0-11-3v-3Z"/>
  <path fill="#EB809B" d="M62 119h3l2 6h-2l-1 2-5 5-3 1-2 1-2-1 2-1 3-2 3-1 3-5-1-5Z"/>
  <path fill="#DCA25E" d="M68 42v2l3 1-10 4v7h-1c-1-8-1-8 2-11s3-3 6-3Z"/>
  <path fill="#E5C889" d="M77 39v1h-2c-9 2-9 2-15 8l1-6c5-4 10-3 16-3Z"/>
  <path fill="#CD4D4B" d="m33 107 7 4-3 1v2l1 2 1 3-1 6-2-7-1-2-2-9Z"/>
  <path fill="#EEC362" d="m137 96 8 1-1 4h-9l2-5Z"/>
  <path fill="#E89CAD" d="m160 134 11 3 3 1 3 1 3 1 3 3c-8-1-15-4-23-7v-2Z"/>
  <path fill="#DE9D45" d="M91 119h1v3l2 6-1 6h-4l2-15Z"/>
  <path fill="#E2BA51" d="M171 70c2 4 0 7-1 11l-2 3-1 3h-3c1-4 2-7 5-11l2-6Z"/>
  <path fill="#EBC469" d="M98 56h11l-2 7-3-2-2 2v-5l-4-1v-1Z"/>
  <path fill="#EDC04C" d="M143 59h2l1 5h18v1h-21v-6Z"/>
  <path fill="#EEC863" d="m164 63 5 2 1 3 2 1-7 4v-3l-1-7Z"/>
  <path fill="#826D4C" d="M119 147h11v1h-7v17h-1v-9l-3-9Z"/>
  <path fill="#DB7F1F" d="m104 94 2 1c-4 3-6 4-11 5l-1 2-2-2 5-3 2-2 5-1Z"/>
  <path fill="#DA7025" d="m45 90 4 3 6 4v2l4 2-9-4-2-2-3-5Z"/>
  <path fill="#ECCA55" d="M117 122h1l1 12h-3l-2-9 2 1 1-4Z"/>
  <path fill="#CB464B" d="m68 90-3 1-2 4-6-2v-2l11-1Z"/>
  <path fill="#D88F49" d="m64 71 3 3v6l1 9c-3-3-4-8-4-13v-5Z"/>
  <path fill="#725A54" d="m78 143 1 3 6 1-3 2-5-2-3-1-2-1-1 2v-3l7-1Z"/>
  <path fill="#E7B843" d="m80 135 5 1 2 4-1 3h-3v-7l-3-1Z"/>
  <path fill="#E07A1E" d="m71 94 1 2 3 1v5H63v-1h9l-1-7Z"/>
  <path fill="#D78631" d="m163 88 2 2-3 4h-2l-1 2c-1 3-3 3-6 4l1-1 9-11Z"/>
  <path fill="#E7D3B1" d="m104 60 2 1v2h2l1 5-4-2v-2l-4 3v-4h2l1-3Z"/>
  <path fill="#F3DABA" d="M108 126c2 3 2 3 2 7h-5c1-5 1-5 3-7Z"/>
  <path fill="#CB5260" d="m140 125 3 1-2 1v2l-2 9h-2l1-5v-3l2-5Z"/>
  <path fill="#CE5563" d="M173 108v7l-1 2-1 2h-1l1-7-3-1 5-3Z"/>
  <path fill="#E5D1C0" d="M114 91h2l2 7-1 2-4-5 1-4Z"/>
  <path fill="#E3A546" d="m70 45 2 1-4 3h-7c5-4 5-4 9-4Z"/>
  <path fill="#E9CF8D" d="m144 40 4 2 1 8v5h-1l-1-10-3-1v-4Z"/>
  <path fill="#E7BC4D" d="m100 65 1 2-3 6h-5l3-1v-5l4-2Z"/>
  <path fill="#DF7116" d="m130 103 2 1-3 4-1 2-5 3v-2l2-1 3-5 2-2Z"/>
  <path fill="#DD5977" d="m38 97 2 1-1 1a91 91 0 0 0 0 8v2l-3-2c0-4 0-6 2-10Z"/>
  <path fill="#EAB54B" d="M98 126h1l-1 7-5 1v-2h2l1-2 2-4Z"/>
  <path fill="#E5A74E" d="m61 59 2 1-2 5h-6l3-1v-3h3v-2Z"/>
  <path fill="#EDBE5B" d="M55 61h3v3l-8 1h-5v-1h9l1-3Z"/>
  <path fill="#DE9136" d="M61 50h6l-2 3h-3v5h-1v-8Z"/>
  <path fill="#ECCE82" d="m166 62 7 4v5l-1-3h-2l-4-4v-2Z"/>
  <path fill="#E39231" d="m99 118 3 1Zm-2 1h2l-5 8c1-6 1-6 3-8Z"/>
  <path fill="#E6AD33" d="M74 48h9v2h-7l-2-1v-1Z"/>
  <path fill="#603831" d="M134 144h2l2 7h-2l-1-4h-2l1-3Z"/>
  <path fill="#E6A43D" d="M96 123c2 3 0 6-1 9h-2l3-9Z"/>
  <path fill="#EBB143" d="M153 95h5l-5 5-4-1 3-1 1-3Z"/>
  <path fill="#E6B35F" d="M65 42h3v2l3 1-9 1 3-4Z"/>
          </svg>
          </div>
          <Typography align="center" 
            sx={{
              fontFamily: "Inter",
              fontSize: "27px",
              fontWeight: 400,
              color: "#000000",
              marginBottom:"29px"
            }}
          >
            congratulations you are successfully verified
          </Typography>
          <div style={{textAlign:'right', display:'flex', flexDirection:'column',paddingTop:"29px",borderTop:"1px solid #E2E8F0"}}>
         
          <Typography component="div" align="center" sx={{ fontWeight: '700',fontSize:'45px',textAlign:'left' , alignSelf:'self-start', marginBottom:"29px" }}>
          Free 1-week trial of the platform
          </Typography>

          <Typography component="div" align="center" sx={{ fontWeight: '700',fontSize:'18px',textAlign:'left' , alignSelf:'self-start', marginBottom:"29px" }}>
          After which your plan will be ${this.state.lastElementWithGreatestId.attributes.price} monthly subscription
          </Typography>
         
          <Typography align="center" sx={{ fontWeight: '700' ,textAlign:'left' , justifySelf:'start', fontSize:'18px', color:"#1E293B", fontFamily:"Inter", marginBottom:"29px"}}>
          You can cancel the subscription at any time.
          </Typography>
          </div>

          <Button
            variant="contained"
            fullWidth
            sx={{
              fontSize: '16px',
              padding: '16px',
              backgroundColor: '#067B3E',
              color: "white",
              fontFamily: "Inter",
              fontWeight: 700,
              lineHeight: "24px",
              textTransform: "none",
              height: "56px",
              borderRadius: "8px",
              marginBottom:"29px",
              '&:hover': {
                backgroundColor: '#067B3E', 
                color: 'white',          
              },
            }}
            data-test-id="ContinueButtonFirst"
            onClick={() => this.setState({isFormVisible:!this.state.isFormVisible})}
          >
            Start free trial
          </Button>

          <Button
            variant="contained"
            fullWidth
            data-test-id="CancleButtonFirst"
            onClick={() => this.handleLoginNavigation()}
            sx={{
              fontSize: '16px',
              padding: '16px',
              backgroundColor: '#F1F5F9',
              color:'#64748B',
              fontFamily: "Inter",
              lineHeight: "24px",
              fontWeight: 700,
              height: "56px",
              borderRadius: "8px",
              textTransform: "none",
              cursor: "pointer",
              '&:hover': {
                backgroundColor: '#F1F5F9', 
                color: '#64748B',          
              },
            }}
          >
            Cancel
          </Button>
        </Paper>
        </Box>
        }

        {this.state.isFormVisible && 
        <Box sx={{ position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', }}>
        <img src={imgSignupLogo} width={"53px"} height={"43px"} style={{
        position: 'absolute',
        top: '0',
        left: '0',
      }}/>
        <Paper

sx={{
  padding: "0px 32px",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}}
elevation={0}
>
        {this.PaymentForm()}
        </Paper>
        </Box>
        }
       
      </SideImgLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
});


// Customizable Area End
