import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { StyleSheet } from "react-native";
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Divider,
  Grid,
  Avatar,
  Typography as TypographyMui,
  Button as ButtonMui,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Pagination
} from '@mui/material';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import CloseIcon from '@mui/icons-material/Close';

// import LocationOnIcon from '@mui/icons-material/LocationOn';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import EditProfileDrawer from './components/EditProfileDrawer'
import SubscriptionCancelModal from "./components/SubscriptionCancelModal";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  pageSpesificStyle = {
  tileText :{
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px',
    textAlign: 'left',
  },
  subText :{
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '26px',
    textAlign: 'left',
    color : '#64748B'
  }

  }

  navigationMenuProps = {
    id:"NavigationMenu",
    navigation:this.props.navigation
  }

  renderOrderStatus = (status: string) => {
    let color = '';
    switch (status) {
      case 'In Progress':
        color = '#F59E0B';
        break;
      case 'Order Complete':
        color = '#10B981';
        break;
      case 'Confirm Sent':
        color = '#10B981';
        break;
      case 'Cancelled':
        color = '#EF4444';
        break;
      default:
        color = '#000';
    }
    return (
      <Typography style={{ color, fontWeight: 'bold', textAlign:"center" }}>
        {status}
      </Typography>
    );
  };

  renderConditionalComponent = (condition: boolean, ComponentIfTrue: JSX.Element, ComponentIfFalse: JSX.Element): JSX.Element => {
    return condition ? ComponentIfTrue : ComponentIfFalse;
  }

  deleteAccount = () => (
    <Dialog open={this.state.isdeleteAccount} onClose={this.handleCloseDeleteAccountModel}>
      <Box display="flex" justifyContent="space-between">
      <DialogTitle fontWeight="bold">Delete Account</DialogTitle>
      <Box >
        <CloseIcon 
          sx={{
            margin: "19px", 
            cursor: "pointer"
          }} 
          onClick={this.handleCloseDeleteAccountModel}
        />
      </Box>
      </Box>
      <Divider/>
      <DialogContent>
        <TypographyMui variant="body1" fontWeight="bold" gutterBottom textAlign="center" fontFamily="Inter">
          Are you sure you want to delete your account?
        </TypographyMui>
        <TypographyMui variant="body2" color="textSecondary" textAlign="center" m="20px 0" fontFamily="Inter">
          You will lose your subscription and order data.
        </TypographyMui>
      </DialogContent>
      <DialogActions sx={{display: "block"}}>
        <ButtonMui
          onClick={this.confirmDeleteAccount}
          variant="contained"
          color="success"
          fullWidth
          sx={styles1.confirmBtn}
        >
          Confirm
        </ButtonMui>
        <ButtonMui
          onClick={this.handleCloseDeleteAccountModel}
          variant="text"
          fullWidth
          sx={styles1.cancelBtn}
        >
          Cancel
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );

  logoutAccount = () => (
    <Dialog onClose={this.handleCloselogoutAccountModel} open={this.state.islogoutAccount}>
      <Box justifyContent="space-between" display="flex">
      <DialogTitle fontWeight="bold">Logout</DialogTitle>
      <CloseIcon onClick={this.handleCloselogoutAccountModel} sx={{ cursor: "pointer",margin: "19px"}} />
      </Box>
      <Divider/>
      <DialogContent>
        <TypographyMui textAlign="center" variant="body1" fontFamily="Inter" fontWeight="bold" gutterBottom >
          Are you sure you want to logout your account?
        </TypographyMui>
      </DialogContent>
      <DialogActions sx={{display: "block"}}>
        <ButtonMui variant="contained" color="success" fullWidth sx={styles1.confirmBtn} onClick={this.handleLogoutAccount} >
          Confirm
        </ButtonMui>
        <ButtonMui onClick={this.handleCloselogoutAccountModel} variant="text" fullWidth sx={styles1.cancelBtn} >
          Cancel
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ width:'100vw' }}>
        <Loader loading={this.state.loading} />
        <EditProfileDrawer open={this.state.isEditOpen} onClose={this.chageIsEditOpen}  user={this.state.user}      
          submitFn={(e)=>{this.handelSubmigForm(e)}} 
          onOpenCropImageModal={this.handleOpenCropImageModal}
          onCloseCropImageModal={this.handleCloseCropImageModal}
          onSaveCropImage={this.handleSaveCroppedImage}
          isOpenCropImageModal={this.state.isOpenCropImageModal}
        /> 
        
        <SubscriptionCancelModal 
          open={this.state.isSubscriptionCancelModalOpen}
          onClose={this.closeSubscriptionCancelModal}
          onConfirm={this.confirmCancelSubscription}
        />

      <NavigationMenu {...this.navigationMenuProps} cartCount={this.state.cartCount}/>  
      <Grid container spacing={2} style={{ justifyContent:'center', alignItems:'center'}}>
      <Grid sx={{
    width: {
      xs: "100%", 
      sm: "100%",  
      md: "93%", 
      lg: "88%",  
      xl: "77%" 
    }
  }} item>
        <Box sx={{ width: "100%" , mt: 4 , border:'none',boxShadow:'none'}}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Box sx={{display:'flex', flexDirection:'column'}}>
                <Avatar
                src= {this.state.user.attributes?.photo_url??""}
                sx={{ width: '132px', height: '132px', mr: '86px' , border:'4px solid #F0E5FF' }}
              >{}</Avatar>
              <Box>
                <ButtonMui
                      variant="contained"
                      size="small"
                      onClick={this.chageIsEditOpen}
                      sx={{
                        marginTop: '20px',
                        marginLeft:'12px',
                        width: '116px',
                        height: '32px',
                        padding: '10px 21px',
                        gap: '8px',
                        borderRadius: '18px',
                        border: '1px solid transparent',
                        borderTopWidth: '1px',
                        textTransform:'none',
                        fontSize:'12px',
                        opacity: 1, // Opacity 0 would make it invisible, set to 1 for visible
                        backgroundColor: '#067B3E',
                        '&:hover': {
                          backgroundColor: '#056635', // optional, adjust hover effect
                        }
                      }}
                      
                    >
                      Edit profile
                    </ButtonMui>
              </Box>
              </Box>
              
              <Box>
              <TypographyMui
                sx={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '26px',
                  textAlign: 'left',
                  color: "#0F172A",
                  marginBottom: "15px"
                }}
              >{this.state.user.attributes?.contact_name}
               </TypographyMui>
               <div style={{display:"flex", alignItems:'center' , gap:'5px', marginBottom: "17px", color: "#64748B"}}>
               <FmdGoodIcon  sx={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '16px',
                  fontWeight: 400,
                  textAlign: 'center',
                  color : '#64748B'
                }}/>
               <TypographyMui color="text.secondary"
                 sx={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '26px',
                  textAlign: 'left',
                  color : '#64748B'
                
                }}
                >
                  {this.state.user.attributes?.state}
                </TypographyMui>
               </div>
                {this.state.user.attributes?.seller_type &&
               <TypographyMui variant="body2" color="text.secondary" 
                sx={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '26px',
                  textAlign: 'left',
                  color : '#64748B',
                  mb:1
                }}
                >
                  Hemp Provider : {this.state.user.attributes?.seller_type}
                </TypographyMui>
  }

                <TypographyMui variant="body2" color="text.secondary" 
                sx={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '26px',
                  textAlign: 'left',
                  color : '#64748B'
                }}
                >
                  License Number :  #{this.state.user.attributes?.license_number}
                </TypographyMui>
                <Box sx={{ display: 'flex',justifyContent: 'space-between',alignItems:'center',flexDirection:'row', gap:'20px' , marginTop:'20px'}}>
                  
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                      <PhoneEnabledIcon fontSize="small" sx={{ ...this.pageSpesificStyle.subText , mr:1 }} />
                      <TypographyMui variant="body2" sx={{ ...this.pageSpesificStyle.subText , mr:1 }}>{this.state.user.attributes?.phone_number}</TypographyMui>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <EmailRoundedIcon fontSize="small" sx={{ ...this.pageSpesificStyle.subText , mr:1 }} />
                      <TypographyMui variant="body2" sx={{ ...this.pageSpesificStyle.subText , mr:1 }}>{this.state.user.attributes?.email}</TypographyMui>
                    </Box>
                  
                  
            </Box>
              </Box>
                  <Box display="flex" justifyContent="flex-end" width="100%">
                    <Box display="flex" flexDirection="column" gap="49px">
                      <ButtonMui onClick={this.handleOpenLogoutAccountModel} variant="contained" size="small" sx={styles1.bothBtn}>
                        Logout
                      </ButtonMui>
                      {this.logoutAccount()}

                      <ButtonMui variant="contained" onClick={this.handleOpenDeleteAccountModel} sx={styles1.bothBtn} size="small">
                        Delete Account
                      </ButtonMui>
                      {this.deleteAccount()}
                    </Box>
                  </Box>

            </Box>

            

            <Divider sx={{ my: 2 }} />

            <TypographyMui variant="h6" gutterBottom sx={this.pageSpesificStyle.tileText}>
              Business Information
            </TypographyMui>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TypographyMui variant="subtitle2" sx={{...this.pageSpesificStyle.subText,fontSize:'16px',fontWeight:'bold'}}>
                  Business Name
                </TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={{...this.pageSpesificStyle.subText,fontSize:'16px',fontWeight:'bold'}}>
                  Address
                </TypographyMui>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TypographyMui variant="subtitle2" sx={this.pageSpesificStyle.subText}> {this.state.user.attributes?.name_of_business}</TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={this.pageSpesificStyle.subText}>
                  {this.state.user.attributes?.address}
                </TypographyMui>
              </Grid>
           
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <TypographyMui variant="subtitle2" sx={{...this.pageSpesificStyle.subText,fontSize:'16px',fontWeight:'bold'}}>
                  {this.state.user.attributes?.type === "BuyerAccount"?"Buyer": "Seller"} License
                </TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={{...this.pageSpesificStyle.subText,fontSize:'16px',fontWeight:'bold'}}>
               
                </TypographyMui>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TypographyMui variant="subtitle2" sx={this.pageSpesificStyle.subText}>  #{this.state.user.attributes?.license_number}</TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={this.pageSpesificStyle.subText}>
                </TypographyMui>
              </Grid>
              </Grid>
            
            <Divider sx={{ my: 2 }} />

            {this.state.user.attributes?.subscribed &&
            <>
            <Box sx={{ display: 'flex',alignItems:"center",justifyContent:"space-between", mb: 2 }}>
                <TypographyMui variant="h6" gutterBottom sx={this.pageSpesificStyle.tileText}>
                  Subscription Details
                </TypographyMui>
                <ButtonMui onClick={this.openSubscriptionCancelModal} variant="text" style={{textTransform: "none", marginRight:"100px"}}>
                  Cancel Subscription
                </ButtonMui>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TypographyMui variant="subtitle2" sx={{...this.pageSpesificStyle.subText,fontSize:'16px',fontWeight:'bold'}}>
                Subscription status
                </TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={{...this.pageSpesificStyle.subText,fontSize:'16px',fontWeight:'bold'}}>
                Subscribed on
                </TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={{...this.pageSpesificStyle.subText,fontSize:'16px',fontWeight:'bold'}}>
                Renewal date
                </TypographyMui>
                
              </Grid>
              <Grid item sm={3}>
              <TypographyMui variant="body2" color="text.secondary" sx={this.pageSpesificStyle.subText}>
                Active
                </TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={this.pageSpesificStyle.subText}>
                {this.state.user.attributes?.start_date.split("T")[0]}
                </TypographyMui>
                <TypographyMui variant="body2" color="text.secondary" sx={this.pageSpesificStyle.subText}>
                {this.state.user.attributes?.end_date.split("T")[0]}
                </TypographyMui>
              </Grid>
             
           
            </Grid>
            
            </>}

            <Divider sx={{ my: 2 }} />
            <TypographyMui sx={styles1.orderText}>
              Orders
            </TypographyMui>
            <Box>
              <TableContainer>
                <Table aria-label="order table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ paddingLeft: 0, ...styles1.headTableCellText }}>Merchant</TableCell>
                      <TableCell sx={styles1.headTableCellText}>Item(s)</TableCell>
                      <TableCell align="center" sx={styles1.headTableCellText}>Total Cost</TableCell>
                      <TableCell sx={styles1.headTableCellText}>Date</TableCell>
                      <TableCell sx={styles1.headTableCellText}></TableCell>
                      <TableCell align="center" sx={{ paddingRight: 0, ...styles1.headTableCellText }}>Order Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.orders.map((order, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ paddingLeft: 0, ...styles1.commonText }}>
                          {order.name ?? '-'}
                        </TableCell>
                        <TableCell sx={styles1.commonText}>
                          {order.order_items.map((item, idx) => (
                            <TypographyMui key={idx}>
                              {`${item.title} - ${item.sub_title}(${item.quantity})`}
                            </TypographyMui>
                          ))}
                        </TableCell>
                        <TableCell align="center" sx={styles1.commonText}>
                          ${order.order_items.reduce((total, item) => total + item.price, 0).toFixed(2)}
                        </TableCell>
                        <TableCell sx={styles1.commonText}>
                          {order.date}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}>
                          {this.renderConditionalComponent(
                            this.state.isBuyerAccount,                          
                            <Box display="flex" justifyContent={'space-between'} alignItems={'center'} gap={"10px"}>
                              <ButtonMui data-test-id="BuyerConfirmButton" variant="contained" sx={styles1.tableButtonStyles} onClick={() => this.handleConfirmRecieptOrder(order.order_id)} disabled={order.status !== "Confirm Sent"} size="small">
                                Confirm Receipt
                              </ButtonMui>
                              <ButtonMui data-test-id="BuyerCancelButton" variant="contained" onClick={() => this.handleCancleOrder(order.order_id)} sx={styles1.tableButtonStyles} disabled={order.status !== "In Progress"} size="small">
                                Cancel Order
                              </ButtonMui>
                              <ButtonMui data-test-id="BuyerWriteReviewButton" variant="contained" sx={styles1.tableButtonStyles} 
                              disabled={order.status !== "Order Complete"}
                               size="small" onClick={() => this.handleWriteReviewNavigation(order.order_items[0].product_id)}>
                                Write review
                              </ButtonMui>
                            </Box>,
                            <Box display="flex" gap={4}>
                              <ButtonMui variant="contained" onClick={() => this.handleConfirmSentOrder(order.order_id)} data-test-id="SellerSentButton" sx={styles1.tableButtonStyles} disabled={order.status !== "In Progress"} size="small">
                                Confirm Sent
                              </ButtonMui>
                              <ButtonMui variant="contained" onClick={() => this.handleCancleOrder(order.order_id)} data-test-id="SellerCancelButton" sx={styles1.tableButtonStyles} disabled={order.status !== "In Progress"} size="small">
                                Cancel Order
                              </ButtonMui>
                            </Box>
                          )}
                        </TableCell>
                        <TableCell sx={{ paddingRight:0, borderBottom: "none" }}>{this.renderOrderStatus(order.status)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {this.renderConditionalComponent(
              this.state.orders.length > 0,
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                margin={"30px 0px"}
              >
                <Pagination
                  count={Math.ceil(this.state.totalRecords / this.state.per_page)}
                  page={this.state.page}
                  data-test-id="pagination"
                  onChange={this.handlePageChange}
                  siblingCount={1}
                  boundaryCount={1}
                  sx={styles1.paginationColor}
                />
              </Box>,
              <React.Fragment></React.Fragment>
            )}
          </Box>
        </Box>
      </Grid>
      </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles1 = {
  paginationColor: {
    "& .MuiPaginationItem-root": {
      color: "#000",
    },
    "& .Mui-selected": {
      color: "#6200EA",
      backgroundColor: "#fff !important",
      textDecoration: "none",
    },
    "& .MuiPaginationItem-ellipsis": {
      color: "#000",
    },
  },
  tableButtonStyles:{
    backgroundColor: "#007A2F1A",
    color: "green",
    textTransform: "none",
    padding: "6px 10px",
    "&:hover": {
      backgroundColor: "#007A2F1A",
      color: "green",
    },
  },
  commonText:{
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: 500,
    color:"black",
    borderBottom: "none",
  },
  orderText:{
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 700,
  },
  headTableCellText:{
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 700,
    borderBottom: "none"
  },
  confirmBtn: {
    marginBottom: "10px",  
    fontWeight: 700, 
    fontFamily: "Inter", 
    fontSize :"14px", 
    textTransform: "capitalize"
  },
  cancelBtn:{
    background: '#E2E8F0', 
    color: "grey" , 
    fontFamily: "Inter", 
    fontSize :"14px",
    textTransform: "capitalize", 
    marginLeft: "0 !important",
    fontWeight: 700
  },
  bothBtn:{
    height: '32px',
    padding: '10px 21px',
    gap: '8px',
    borderRadius: '18px',
    border: '1px solid transparent',
    borderTopWidth: '1px',
    textTransform: 'none',
    fontSize: '12px',
    opacity: 1,
    backgroundColor: '#067B3E',
    '&:hover': {
      backgroundColor: '#056635',
    },
    fontFamily:"Inter",
    fontWeight: 700,
  },
}

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
});


// Customizable Area End
