import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

interface SubscriptionAttributes {
  name: string;
  price: string;
  description: string;
  valid_up_to: string;
  expired: boolean;
  image_link: string | null;
  subscribed: boolean;
}

interface SubscriptionData {
  id: string;
  type: string;
  attributes: SubscriptionAttributes;
}

interface ErrorResponseJson{
  error: string;
}

interface AccountAttributes {
  address: string | null;
  business_description: string | null;
  contact_name: string;
  email: string;
  phone_number: number;
  type: string;
  county: string;
  state: string;
  license_number: number;
  name_of_business: string;
  unique_auth_id: string;
  created_at: string;
  updated_at: string;
  email_verified: boolean;
  subscribed: boolean;
  start_date: string;
  end_date: string;
  unsubscribed: boolean;
  subscription_expiration: string | null;
  photo_url: string | null;
}

interface AccountData {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

interface AccountResponse {
  data: AccountData;
  errors?: [];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token:string|undefined|null;
  subscriptionData : SubscriptionData[];
  lastElementWithGreatestId: SubscriptionData;
  isFormVisible: boolean;
  cardDetailsFormValues: any;
  stripetokenData:any;
  ApiError:string;
  payNowLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Subscriptionbilling2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
   subscriptionApiCallID: string ="";
   createSubscriberApiCallID:string ='';
   useDetailsApiCallID:string =""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
    
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      subscriptionData : [],
      lastElementWithGreatestId: {
        id: "",
        type: "",
        attributes: {
          name: "",
          price: "",
          description: "",
          valid_up_to: "",
          expired: false,
          image_link: null,
          subscribed: false,
        },
      },
      isFormVisible : false,
      cardDetailsFormValues: null,
      stripetokenData: null,
      ApiError:'',
      payNowLoading:false,
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    
    
    const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
     var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

    switch (apiRequestCallId) {
      case this.subscriptionApiCallID:
        this.setState({ subscriptionData: responseJson.data });
        const lastElementWithGreatestId = responseJson.data.reduce(
          (max: SubscriptionData, subscription: SubscriptionData) => {
            return Number(subscription.id) > Number(max.id) ? subscription : max;
          }
        );
        this.setState({ lastElementWithGreatestId });
        break;
  
      case this.createSubscriberApiCallID:
        if(responseJson.message) {
          this.setState({payNowLoading: false});
          this.handleResponseCreateSubscriber(responseJson);
        } else if(responseJson.errors) {
          this.setState({payNowLoading: false});
        }
        break;
  
      case this.useDetailsApiCallID:
        this.handleResponseOfLoggedInUser(responseJson);
        break;
  
      default:
        break;
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
   componentDidMount():any{
      this.getToken();
      this.getLoggendInUserData()
      
      this.getSubscriptionDetails() 
    }

    handleResponseCreateSubscriber = (responseJson: ErrorResponseJson) => {
      if (responseJson.error) {
        alert('Error in Stripe Service');
      } else {
        this.routetopage('Filteritems');
      }
    }

    handleResponseOfLoggedInUser = (responseJson : AccountResponse) => {
      this.saveLoggedInUserData(responseJson.data);
      if (responseJson.data.attributes.subscribed) {
        this.routetopage('Filteritems');
      }
      if (responseJson.errors && responseJson.errors.length > 0) {
        this.routetopage('EmailAccountLoginBlock');
      } else if (
        responseJson.data.attributes.subscribed ||
        responseJson.data.attributes.type !== "BuyerAccount"
      ) {
        this.routetopage('Settings2');
      }
    };

  routetopage= (screenName: string) => {

    if (screenName.length > 0) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      runEngine.sendMessage(message.id, message);
    }
  }

  getToken =()=>{
    const accessToken:string|null|undefined =localStorage.getItem('accessToken')
    localStorage.getItem('authToken');
    if(localStorage.getItem('authToken') !== null || localStorage.getItem('authToken') !== undefined  ){
      
      this.setState({token:localStorage.getItem('authToken')?.toString()})
    }
    else{
      this.routetopage('EmailAccountLoginBlock');
    }
  }


  getSubscriptionDetails() {

    const headers = {

      "Content-Type":`${configJSON.validationApiContentType}`,
      "token": this.state.token || localStorage.getItem('authToken') 
    }

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.subscriptionApiCallID = getValidationsMsg.messageId;



    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `${configJSON.StripeApiFeatch}`

    );



    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.validationApiMethodType

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);



    return true;

  }

 


  createStripeToken = async (values: {
    cardNumber:string;
    expiration:string;
    cvv:string;
  }) => {
    this.setState({ ApiError : `` });
    const [expMonth, expYear] = (values.expiration || "").split('/');
    const formData = new URLSearchParams();
    formData.append('card[number]', values?.cardNumber || "");
    formData.append('card[exp_month]', expMonth);
    formData.append('card[exp_year]', expYear);
    formData.append('card[cvc]', values?.cvv || "");
    /*istanbul ignore next*/
    const prod = window.location.origin === 'https://www.canna-gen.com' ? configJSON.publishment : configJSON.testToken
    await fetch('https://api.stripe.com/v1/tokens', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${prod}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          this.handleStripeTokenGenerationError(data.error);
          this.setState({payNowLoading: false});
        } else {
          this.setState({ cardDetailsFormValues: values });
          this.setState({stripetokenData:data})
          this.createSubscriber()
        }
      });
  };

  handleStripeTokenGenerationError = (error: {code :string,message:string}) => {
    switch (error.code) {
      case 'incorrect_number':
        this.setState({ ApiError : `card numeber : ${error.message}` });
        break;
      case 'invalid_expiry_month':
        this.setState({ ApiError : `expiry date : ${error.message}` });
        break;
      case 'invalid_cvc':
        this.setState({ ApiError : `cvv : ${error.message}` });
        break;
      default:
        this.setState({ ApiError : `${error.message}` });
        break;
    }
  };



    createSubscriber() {
    const { lastElementWithGreatestId } = this.state;
    const headers = {
      "Content-Type": `${configJSON.validationApiContentType}`,
      "token": this.state.token || localStorage.getItem('authToken')
    };

    const payload = {
      "account": {
        "subscription_id":lastElementWithGreatestId.id,
        "stripe_token": `${this.state.stripetokenData.id}`
      }
    };

    const createSubscriberMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSubscriberApiCallID = createSubscriberMsg.messageId;

    createSubscriberMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.CreateCustomer
    );

    createSubscriberMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createSubscriberMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    createSubscriberMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(createSubscriberMsg.id, createSubscriberMsg);

    return true;
  }


  getLoggendInUserData(){

    const headers = {

      "Content-Type":`${configJSON.validationApiContentType}`,
      "token": this.state.token || localStorage.getItem('authToken') 
    }

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.useDetailsApiCallID = getValidationsMsg.messageId;



    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `${configJSON.UserInfoApi}`

    );



    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.validationApiMethodType

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);



    return true;

  }
  saveLoggedInUserData(responseJson: any) {
    if (responseJson ) {
     localStorage.setItem('user',JSON.stringify(responseJson))
    }
  }

  handleLoginNavigation = () => {
    const login: Message = new Message(getName(MessageEnum.NavigationMessage));
    login.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    login.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(login);
  }
  
  // Customizable Area End
}
