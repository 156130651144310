import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
type NullableString = string | null | undefined;
type NullableNumber = number | null | undefined;
interface AccountAttributes {
  address: NullableString;
  business_description: NullableString;
  contact_name: NullableString;
  email: NullableString;
  phone_number: NullableNumber;
  type: NullableString;
  county: NullableString;
  state: NullableString;
  license_number: NullableNumber;
  name_of_business: NullableString;
  unique_auth_id: NullableString;
  created_at: NullableString;
  updated_at: NullableString;
  email_verified: boolean | null | undefined; // You can keep this as is or create another alias if needed
  photo_url: NullableString;
}

export interface Account {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

interface AccountResponse {
  data: Account;
}

interface OrderItem {
  id: number;
  price: number;
  quantity: number;
  taxable: boolean;
  taxable_value: number;
  created_at: string;
  updated_at: string;
  title: string;
  sub_title: string;
  shopping_cart_id: string;
  product_id: number;
  init_price: string;
  order_management_order_id: number;
  status: string | null;
  seller_acc_id: string;
  available_quantity: number;
}

interface Order {
  name: string;
  order_items: OrderItem[];
  order_id: number;
  status: string;
  date: string;
}

interface Meta {
  current_page: number;
  total_pages: number;
  total_count: number;
}

interface OrderResponse {
  message: string;
  order: {
    order: Order[];
    meta: Meta;
  };
}

interface ButtonOrderResponse{
  message: string;
  order: Order[];
}

interface CartItem {
  id: number;
  title: string;
  sub_title: string;
  price: number;
  quantity: number;
  product_id: number;
  seller_acc_id: string;
  image_url: string;
  order_management_order_id: number | null;
  status: string | null;
  available_quantity: number;
}

// @ts-ignore
import {baseURL} from '../../../framework/src/config.js'
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  user : Account|any;
  isEditOpen : boolean;
  signUpFormData:Account|any;
  isSubscriptionCancelModalOpen: boolean;
  isBuyerAccount: boolean;
  orders: Order[];
  loading: boolean;
  page: number;
  per_page: number;
  totalRecords: number;
  cartCount: number;
  isdeleteAccount: boolean;
  islogoutAccount: boolean;
  isOpenCropImageModal: boolean;
  croppedImage: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updateAccountApiCallId:string =""
  cancelSubscriptionApiCallId:string = ""
  getUserDetailsApiCallID: string = ""
  getOrderItemListApiCallID: string = ""
  cancelOrderItemListApiCall: string = ""
  confirmRecieptApiCall: string = ""
  confirmSentApiCall: string = ""
  getCartOrdersCallId : string = ""
  deleteAccountApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    let parsedUser = null;
    const user = localStorage.getItem('user');
    if(user){
    parsedUser = JSON.parse(user).data || JSON.parse(user);}
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.PostEntityMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      user: parsedUser,
      isEditOpen:false,
      signUpFormData: null,
      isSubscriptionCancelModalOpen: false,
      isBuyerAccount: false,
      orders: [],
      page: 1,
      per_page: 10,
      totalRecords: 0,
      loading: false,
      cartCount: 0,
      isdeleteAccount: false,
      islogoutAccount: false,
      isOpenCropImageModal: false,
      croppedImage: {}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.cancelSubscriptionApiCallId:
          this.handleNavigation("EmailAccountLoginBlock");
          break;
      
        case this.getUserDetailsApiCallID:
          this.handleResponseOfLoggedInUser(responseJson);
          break;
      
        case this.getOrderItemListApiCallID:
          this.handleResponseOfOrderItemList(responseJson);
          break;
      
        case this.cancelOrderItemListApiCall:
          if (!responseJson.errors) {
            this.handleResponseOfCancelOrder(responseJson);
          }
          break;
      
        case this.confirmRecieptApiCall:
          if (!responseJson.errors) {
            this.handleResponseOfConfirmReciept(responseJson);
          }
          break;
      
        case this.confirmSentApiCall:
          if (!responseJson.errors) {
            this.handleResponseOfConfirmSent(responseJson);
          }
          break;

        case this.getCartOrdersCallId:
          if (!responseJson.errors) {
            const filteredCartItems = responseJson?.cart_items?.filter(
              (item: CartItem) => item.order_management_order_id === null
            );
            this.setState({ cartCount: filteredCartItems });
          }
          break;

        case this.deleteAccountApiCallId:
          /*istanbul ignore next*/
          this.handleDeleteApiResponse()
          break;
      
        default:
          console.warn("Unhandled apiRequestCallId:", apiRequestCallId);
          break;
      }      
    }
    // Customizable Area End
  }

  handleDeleteApiResponse = () => {
    /*istanbul ignore next*/
    if(this.state.user.attributes.type === 'BuyerAccount') {
      this.confirmCancelSubscription()
    }
    /*istanbul ignore next*/
    this.handleNavigation("EmailAccountRegistration");
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  componentDidMount(): Promise<void> {
    this.getTokenFromLocalStorage();
    this.getorderApi()
    return Promise.resolve();
  }

  getorderApi = async() => {
    const token = await getStorageData("authToken");
    this.getOrderItemsList(token)
  }

  getTokenFromLocalStorage = async () => {
    const token = await getStorageData("authToken");
    this.setState({ token });
    this.getLoggendInUserData(token);
    this.getOrderItemsList(token);
    this.getCartOrders(token);
  };

  getCartOrders = (token : string) =>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCartOrdersCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_shopping_cart/order_items'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLoggendInUserData = (token : string) => {
    const headers = {
      "Content-Type": `application/json`,
      "token": token
    }
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserDetailsApiCallID = requestMsg.messageId;
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/logged_user"
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true;
  }

  handleResponseOfLoggedInUser = (responseJson : AccountResponse) => {
    this.setState({
      isBuyerAccount : responseJson.data.attributes.type === "BuyerAccount"
    })
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const { token } = this.state;
    this.setState({ page: value }, () => {
      this.getOrderItemsList(token);
    });
  };

  getOrderItemsList = (token : string) => {
    const headers = {
      "Content-Type": `application/json`,
      "token": token
    }
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrderItemListApiCallID = requestMsg.messageId;
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/account_order_item_list?page=${this.state.page}&per_page=${this.state.per_page}`
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true;
  };

  handleResponseOfOrderItemList = (responseJson : OrderResponse) => {
    if(responseJson.order && responseJson.order.order && responseJson.order.order.length > 0) {
      this.setState({
        orders: responseJson.order.order,
        totalRecords: responseJson.order.meta.total_count,
        loading: false,
      })
    }
    else{
      this.setState({
        orders: [],
        totalRecords: 0,
        loading: false,
      })
    }
  };

  handleCancleOrder = (orderId : number) => {
    const headers = {
      "Content-Type": `application/json`,
      "token": this.state.token
    }
    const body = {
      order:{
        id: orderId,
      }
    }
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelOrderItemListApiCall = requestMsg.messageId;
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/cancel_order`
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true;
  };

  handleWriteReviewNavigation = (id: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'ProductDiscription');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), `${String(id)}/writeReview`)
    this.send(message);
}

  handleResponseOfCancelOrder = (responseJson : ButtonOrderResponse) => {
    toast.success(responseJson.message);
    this.setState({ loading: false });
    this.getOrderItemsList(this.state.token);
  };

  handleConfirmRecieptOrder = (orderId : number) => {
    const headers = {
      "Content-Type": `application/json`,
      "token": this.state.token
    }
    const body = {
      order:{
        id: orderId,
      }
    }
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.confirmRecieptApiCall = requestMsg.messageId;
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/confirm_receipt`
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true;
  };

  handleResponseOfConfirmReciept = (responseJson : ButtonOrderResponse) => {
    toast.success(responseJson.message);
    this.setState({ loading: false });
    this.getOrderItemsList(this.state.token);
  };

  handleConfirmSentOrder = (orderId : number) => {
    const headers = {
      "Content-Type": `application/json`,
      "token": this.state.token
    }
    const body = {
      order:{
        id: orderId,
      }
    }
    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.confirmSentApiCall = requestMsg.messageId;
    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/confirm_sent`
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true;
  };

  handleResponseOfConfirmSent = (responseJson : ButtonOrderResponse) => {
    toast.success(responseJson.message);
    this.setState({ loading: false });
    this.getOrderItemsList(this.state.token);
  };

  handleNavigation = (screenName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    runEngine.sendMessage(message.id, message);
  }

  chageIsEditOpen=()=>{
    this.setState({isEditOpen:!this.state.isEditOpen})
  }
  handleOpenCropImageModal = () => {
    this.setState({ isOpenCropImageModal: true })
  };

  handleCloseCropImageModal = () => {
    this.setState({ isOpenCropImageModal: false })
  };

  handleSaveCroppedImage = (croppedImageFile: any) => {
    this.setState({ croppedImage: croppedImageFile })
    this.setState({ isOpenCropImageModal: false })
  };

  handelSubmigForm(data:any){
    this.setState({signUpFormData:data})
    this.updateAccountApiCall()
   
  }
  updateAccountApiCall(): any {
    // Prepare the authorization headers
    const header = {
      "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
      "token": `${localStorage.getItem("authToken")}`,
    };
  
    const {
      contact_name,
      email,
      phone_number,
      business_description,
      photo
      // @ts-ignore
    } = this.state.signUpFormData;
  
    const formData = new FormData();
    formData.append("data[attributes][contact_name]", contact_name);
    formData.append("data[attributes][phone_number]", "+" + phone_number);
    formData.append("data[attributes][business_description]", business_description);
  
    if (photo && typeof photo !== 'string') {
      formData.append("data[attributes][photo]", photo);
    }
    if(this.state.user){

      this.setState(
        {user:{...this.state.user,contact_name:contact_name, photo,phone_number}})
    }
    fetch(`${baseURL}/${configJSON.userPatchApi}${this.state.user.id}`, {
      method: 'PATCH',
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
        "token": `${localStorage.getItem("authToken")}`
        
      },
      body: formData 
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      const currentUserData = JSON.parse(localStorage.getItem('user') || '{}');
      const updatedUserData = {
        ...currentUserData,
        ...data.data,
      };
      localStorage.setItem('user', JSON.stringify(updatedUserData));
      window.location.reload()
      this.setState({ 
        user: updatedUserData,
        isEditOpen: false
      });
    })
    
    return true;}

    openSubscriptionCancelModal = () => {
      this.setState({ isSubscriptionCancelModalOpen: true });
    };
  
    closeSubscriptionCancelModal = () => {
      this.setState({ isSubscriptionCancelModalOpen: false });
    };
  
    confirmCancelSubscription = async () => {
      const accountId = this.state.user.id;
      const token = await getStorageData("authToken");
      const apiEndpoint = `bx_block_subscription_billing/recurring_subscriptions/${accountId}/cancel`;
      const header = {
        "Content-Type": "application/json",
        "token" : token
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.cancelSubscriptionApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);

      return this.cancelSubscriptionApiCallId
    };
  
    handleOpenDeleteAccountModel = () => {
      this.setState({ isdeleteAccount: true });
    };

    handleCloseDeleteAccountModel = () => {
      this.setState({ isdeleteAccount: false });
    };

    handleOpenLogoutAccountModel = () => {
      this.setState({ islogoutAccount: true });
    };

    handleCloselogoutAccountModel = () => {
      this.setState({ islogoutAccount: false });
    };

    confirmDeleteAccount = async () => {
      const token = await localStorage.getItem("authToken");
      const header = {
        "Content-Type": configJSON.productApiContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/accounts/${this.state.user.id}?token=${token}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'DELETE'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleLogoutAccount = () => {
      localStorage.clear();
      this.handleNavigation("EmailAccountLoginBlock");
    };

  // Customizable Area End
}
